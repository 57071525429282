import React, { useEffect, useState } from 'react';
import { getContent } from '../core/helpers';

export default function NavBar(props) {
  const [menuLinks, setMenuLinks] = useState([]);

  // request contents from the public folder json
  const getNowCont = async () => {
    await getContent('/assets/nav.json').then((x) => {
      setMenuLinks(x?.links);
    });
  };
  useEffect(() => {
    getNowCont();
  }, []);
  return (
    <>
      <section className="ml-auto menu sticky-top ">
        <nav className="navbar navbar-expand-lg ">
          <div className="row my-2 col-md-12">
            {/* logo */}
            <div id="LogoBox" className="col-md-2">
              <a
                href="/"
                id="Logo"
                className="text-dark  ml-4"
                alt="PKF Qatar"
                style={{ backgroundImage: "url('/media/logo.png')" }}
              >
                {/* Qatar */}
              </a>
              {/* <span className=" logo">Qatar</span> */}
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="fas fa-bars">
                  <i></i>
                </span>
              </button>
            </div>
            {/*  logo */}
            <div id="nav-bar" className="container col-md-10">
              <div className="offcanvas offcanvas-end  ml-5 " id="navbarNav">
                <ul className="navbar-nav  justify-content-end">
                  {menuLinks?.length > 0
                    ? Object.keys(menuLinks)?.map((x) => {
                        return (
                          <li
                            key={menuLinks[x]?.title + menuLinks[x]?.info + Math.random()}
                            className="nav-item"
                          >
                            <a
                              className="nav-link"
                              alt={menuLinks[x]?.info}
                              href={menuLinks[x]?.link}
                              title={menuLinks[x]?.title}
                            >
                              {menuLinks[x]?.title}
                            </a>
                          </li>
                        );
                      })
                    : []}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
}
